import * as CSS from 'csstype'
import theme from 'utils/theme'

const { spacing } = theme

export const showOnlyOnPrint = {
  display: 'none',
  '@media print': {
    display: 'block'
  }
}

export const fontStyles: CSS.Properties = {
  fontWeight: 600
}

export const topRow: CSS.Properties = {
  display: 'flex',
  gap: '40px'
}

export const content: CSS.Properties = {
  margin: spacing(2.5),
  zIndex: 1,
  position: 'relative'
}

export const contentColumn: CSS.Properties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%'
}

export const cardsWrapper = {
  display: 'flex',
  gap: spacing(1),
  margin: spacing([1, 0]),
  '& div': {
    flex: 1
  }
}
