import { memo, useEffect, useRef } from 'react'

import Download from '@carbon/icons-react/lib/Download'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Partner,
  CustomerFindInvoiceFragment,
  useCustomerGetInvoicePdfLinkLazyQuery
} from 'graph/generated/payments/graphql-types'
import { downloadPdfCss } from 'lib/ui/DownloadPdf'
import ContentWrapper from 'lib/ui/DownloadPdf/ContentWrapper'
import PartnerDetails from 'lib/ui/DownloadPdf/PartnerDetails'
import Text from 'lib/ui/Text'
import toast from 'react-hot-toast'
import { useReactToPrint } from 'react-to-print'
import { handleSegmentTracking } from 'utils/analytics'

import { formatIcon } from './styles'
import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import Button, { IButtonProps } from '@/ui/Button/v2'
import InvoicePdfLayout from '@/ui/Pdfs/InvoicePdfLayout'

dayjs.extend(utc)

interface IInvoiceDownload {
  autoDownload?: boolean
  autoDownloadWithButton?: boolean
  btnProps?: IButtonProps
  btnText?: string
  invoice: CustomerFindInvoiceFragment
  onDownloaded?: () => void
  showLink?: boolean
  showIcon?: boolean
  buttonSize?: 'sm' | 'md' | 'lg'
  ignoreMargin?: boolean
}

const InvoiceDownload = memo(
  ({
    autoDownload = false,
    autoDownloadWithButton = false,
    btnText,
    invoice,
    btnProps,
    onDownloaded,
    showLink = false,
    showIcon = true,
    buttonSize = 'md',
    ignoreMargin = false
  }: IInvoiceDownload) => {
    const { brandColor } = useCompanyAndInvoiceContext()

    const partner = invoice?.partner as Partner
    const componentRef = useRef(null)

    const [fetchInvoiceDownload, { data, loading, refetch, error }] =
      useCustomerGetInvoicePdfLinkLazyQuery({
        variables: {
          invoiceID: invoice?.id
        },
        fetchPolicy: 'network-only'
      })

    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    })

    useEffect(() => {
      if (loading || (data === undefined && error === undefined)) return

      if (error) {
        toast.error('Could not get invoice PDF, please try again', {
          id: 'error'
        })
        handleSegmentTracking({
          message: 'Error fetching invoice download',
          error
        })
        return
      }

      const expiresAt = data?.CustomerGetInvoicePdfLink?.expiresAt
      const isExpired = dayjs().isAfter(expiresAt)
      if (isExpired) {
        refetch()
      }

      const url = data?.CustomerGetInvoicePdfLink?.url
      if (url) {
        handleSegmentTracking({
          message: 'Invoice Downloaded',
          data: { invoice }
        })
        window.open(url, '_blank')
        if (onDownloaded) onDownloaded()
      } else {
        handlePrint()
      }
    }, [data, loading, error])

    const handleDownload = async () => {
      try {
        await fetchInvoiceDownload()
      } catch (error) {
        handleSegmentTracking({
          message: 'Error fetching invoice download',
          error
        })
      }
    }

    useEffect(() => {
      if (autoDownload || autoDownloadWithButton) {
        handleDownload()
      }
    }, [autoDownload, autoDownloadWithButton])

    return (
      <>
        <div>
          {(!autoDownload || autoDownloadWithButton) && (
            <Button
              onClick={handleDownload}
              loading={loading}
              variant='outline'
              buttonSize={buttonSize}
              iconPlacement='left'
              display='flex'
              {...btnProps}
            >
              {showIcon && (
                <span
                  css={{
                    ...(ignoreMargin
                      ? { '& svg': { margin: 0 } }
                      : { formatIcon })
                  }}
                >
                  <Download size={16} />
                </span>
              )}
              {btnText && <Text>{btnText}</Text>}
            </Button>
          )}
          {showLink && data?.CustomerGetInvoicePdfLink?.url && (
            <Text size='sm' margin={[0.5, 0, 0]}>
              If you have trouble downloading the invoice you can click {''}
              <a href={data?.CustomerGetInvoicePdfLink?.url}>this link</a>.
            </Text>
          )}
        </div>

        <div ref={componentRef} css={downloadPdfCss.showOnlyOnPrint}>
          <ContentWrapper
            brandColor={brandColor}
            masthead={{
              circleContent: (
                <>
                  Invoice
                  <div css={{ fontSize: '32px' }}>
                    #{invoice?.invoiceNumber}
                  </div>
                </>
              ),
              logo: partner?.paymentsPortalCustomization?.logoUrl,
              logoContent: <PartnerDetails partner={partner} />
            }}
          >
            <InvoicePdfLayout invoice={invoice} />
          </ContentWrapper>
        </div>
      </>
    )
  }
)

InvoiceDownload.displayName = 'InvoiceDownload'

export default InvoiceDownload
