import * as CSS from 'csstype'
import Button, { IButtonProps, Variant } from 'lib/ui/Button'
import theme from 'utils/theme'

import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'

const { colors } = theme

const baseStyles: CSS.PropertiesFallback = {
  fontSize: theme.typography.body.sizes.md,
  borderRadius: '8px'
}

const textVariantFix = {
  textDecoration: 'none !important',
  '&:disabled': {
    color: 'inherit'
  }
}

/**
 * Need to override old theme styles
 * until all apps go live with branding updates
 */
export const setBtnStyles = (variant: Variant) => {
  const { brandColor } = useCompanyAndInvoiceContext()

  switch (variant) {
    case 'primary':
      return brandColor.hasCustomBrandColor
        ? {
            ...baseStyles,
            background: brandColor?.hex,
            color: brandColor.isDark ? colors.light : colors.grey25,
            '&:hover': {
              background: brandColor?.hex
            }
          }
        : {
            ...baseStyles,
            background: colors.primary300,
            color: colors.light,
            '&:hover': {
              background: colors.primary400
            },
            '&:active': {
              background: colors.primary500
            }
          }
    case 'text':
      return brandColor.hasCustomBrandColor
        ? {
            ...baseStyles,
            color: brandColor.hasLowContrastToWhite
              ? colors.grey30
              : brandColor?.hex,
            ...textVariantFix
          }
        : {
            ...baseStyles,
            color: colors.primary,
            ...textVariantFix
          }
    default:
      return baseStyles
  }
}

const BaseButton = ({
  buttonSize,
  display,
  variant = 'primary',
  type,
  children,
  disabled,
  href,
  form,
  id,
  loading,
  name,
  newTab,
  onClick,
  value,
  brandColor,
  iconPlacement,
  padding,
  styles
}: IButtonProps) => (
  <Button
    buttonSize={buttonSize}
    display={display}
    variant={variant}
    type={type}
    disabled={disabled}
    href={href}
    form={form}
    id={id}
    loading={loading}
    name={name}
    newTab={newTab}
    onClick={onClick}
    value={value}
    brandColor={brandColor}
    iconPlacement={iconPlacement}
    padding={padding}
    styles={{ ...setBtnStyles(variant), ...styles }}
  >
    {children}
  </Button>
)

export default BaseButton

export type { IButtonProps }
