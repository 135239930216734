import Footer from './Footer'
import Header from './Header'
import * as contentWrapperCss from './styles'
import { ContentWrapperProps } from './types'

const ContentWrapper = ({
  children,
  brandColor,
  icon
}: ContentWrapperProps) => (
  <>
    <Header brandColor={brandColor} />

    <div css={{ ...contentWrapperCss.content }}>
      <div css={{ ...contentWrapperCss.contentColumn }}>{children}</div>
    </div>
    <Footer brandColor={brandColor} icon={icon} />
  </>
)

export default ContentWrapper
