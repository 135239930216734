import {
  BnplStatus,
  TransactionStatus,
  PayoutStatusEnum,
  SubscriptionPaymentStatus,
  InstallmentStatus,
  InvoiceStatusEnum
} from 'graph/generated/partner/graphql-types'
import {
  InvoiceStatus,
  PaymentStatusEnum
} from 'graph/generated/payments/graphql-types'
import { capitalizeFirstLetter } from 'utils/misc'

export type StatusBadgeType =
  | 'success'
  | 'warning'
  | 'danger'
  | 'dangerLight'
  | 'dangerSoft'
  | 'info'
  | 'outline'
  | 'fill'
  | 'canceled'

/**
 * PaymentStatusEnum has statuses that are not used in the UI, so we need the following to set display text and badge type
 */
export enum PaymentStatusOptionsEnum {
  Failed = 'Failed',
  Paid = 'Paid',
  Processing = 'Processing',
  Scheduled = 'Scheduled',
  Chargeback = 'Chargeback',
  Refunded = 'Refunded'
}

export const paymentStatusOptions = Object.values(PaymentStatusOptionsEnum)

export const setPaymentBadge = (status: PaymentStatusEnum): StatusBadgeType => {
  switch (status) {
    case PaymentStatusEnum.Succeeded:
      return 'success'
    case PaymentStatusEnum.Canceled:
    case PaymentStatusEnum.Failed:
      return 'danger'
    case PaymentStatusEnum.Refunded:
      return 'dangerSoft'
    case PaymentStatusEnum.Chargeback:
      return 'dangerLight'
    case PaymentStatusEnum.Scheduled:
      return 'info'
    case PaymentStatusEnum.Creating:
    case PaymentStatusEnum.Pending:
    case PaymentStatusEnum.Processing:
      return 'warning'
    default:
      return 'fill'
  }
}

export const convertToPaymentStatus = (
  status: PaymentStatusOptionsEnum
): PaymentStatusEnum[] => {
  switch (status) {
    case PaymentStatusOptionsEnum.Failed:
      return [PaymentStatusEnum.Failed, PaymentStatusEnum.Canceled]
    case PaymentStatusOptionsEnum.Paid:
      return [PaymentStatusEnum.Succeeded]
    case PaymentStatusOptionsEnum.Processing:
      return [
        PaymentStatusEnum.Creating,
        PaymentStatusEnum.Pending,
        PaymentStatusEnum.Processing
      ]
    case PaymentStatusOptionsEnum.Scheduled:
      return [PaymentStatusEnum.Scheduled]
  }
}

export const formatPaymentsStatusText = (
  status: PaymentStatusEnum
): PaymentStatusOptionsEnum => {
  switch (status) {
    case PaymentStatusEnum.Canceled:
    case PaymentStatusEnum.Failed:
      return PaymentStatusOptionsEnum.Failed
    case PaymentStatusEnum.Succeeded:
      return PaymentStatusOptionsEnum.Paid
    case PaymentStatusEnum.Creating:
    case PaymentStatusEnum.Pending:
    case PaymentStatusEnum.Processing:
      return PaymentStatusOptionsEnum.Processing
    case PaymentStatusEnum.Scheduled:
      return PaymentStatusOptionsEnum.Scheduled
    case PaymentStatusEnum.Refunded:
      return PaymentStatusOptionsEnum.Refunded
    case PaymentStatusEnum.Chargeback:
      return PaymentStatusOptionsEnum.Chargeback
  }
}

export const setPaymentBadgeTooltipContent = (
  status: PaymentStatusEnum,
  failureReason?: string
) => {
  switch (status) {
    case PaymentStatusEnum.Canceled:
    case PaymentStatusEnum.Failed:
      return 'Customer payment failed.'
    case PaymentStatusEnum.Succeeded:
      return 'Customer payment succeeded.'
    case PaymentStatusEnum.Creating:
    case PaymentStatusEnum.Pending:
    case PaymentStatusEnum.Processing:
      return 'Payment initiated via CC or ACH.'
    case PaymentStatusEnum.Scheduled:
      return 'Invoice payment scheduled.'
    case PaymentStatusEnum.Chargeback:
      return failureReason
        ? `Invoice payment chargedback.<br>Reason: ${failureReason}`
        : 'Invoice payment chargedback.'
    case PaymentStatusEnum.Refunded:
      return 'Invoice payment refunded.'
  }
}

export const setTransactionBadge = (status: TransactionStatus) => {
  switch (status) {
    case TransactionStatus.Succeeded:
      return 'success'
    case TransactionStatus.Created:
      return 'outline'
    case TransactionStatus.Failed:
      return 'danger'
    default:
      return 'warning'
  }
}

export enum PayoutStatusOptionsEnum {
  IN_TRANSIT = 'In transit',
  DEPOSITED = 'Deposited',
  FAILED = 'Failed',
  IN_ANALYSIS = 'In analysis'
}

export const setPayoutBadge = (status: PayoutStatusEnum): StatusBadgeType => {
  switch (status) {
    case PayoutStatusEnum.Canceled:
    case PayoutStatusEnum.Failed:
      return 'danger'
    case PayoutStatusEnum.Succeeded:
    case PayoutStatusEnum.Processing:
      return 'success'
    case PayoutStatusEnum.Pending:
    case PayoutStatusEnum.Scheduled:
      return 'info'
    case PayoutStatusEnum.Approved:
    case PayoutStatusEnum.Analyzing:
    case PayoutStatusEnum.Creating:
      return 'warning'
    default:
      return 'danger'
  }
}

export const formatPayoutStatusText = (
  status?: PayoutStatusEnum
): PayoutStatusOptionsEnum => {
  switch (status) {
    case PayoutStatusEnum.Succeeded:
    case PayoutStatusEnum.Processing:
      return PayoutStatusOptionsEnum.DEPOSITED
    case PayoutStatusEnum.Canceled:
    case PayoutStatusEnum.Failed:
      return PayoutStatusOptionsEnum.FAILED
    case PayoutStatusEnum.Pending:
    case PayoutStatusEnum.Scheduled:
      return PayoutStatusOptionsEnum.IN_TRANSIT
    default:
      return PayoutStatusOptionsEnum.IN_ANALYSIS
  }
}

export const setPayoutBadgeTooltipContent = (
  status?: PayoutStatusEnum,
  failureReason?: string
) => {
  switch (status) {
    case PayoutStatusEnum.Succeeded:
    case PayoutStatusEnum.Processing:
      return 'Payout successfully deposited.'
    case PayoutStatusEnum.Canceled:
    case PayoutStatusEnum.Failed:
      return failureReason
        ? `This payout has failed due to:<br>${failureReason}`
        : 'Payout fails.'
    case PayoutStatusEnum.Pending:
    case PayoutStatusEnum.Scheduled:
      return 'Payout initiated by Alternative.'
    default:
      return 'Payout is being analyzed.'
  }
}

export enum InvoiceStatusOptionsEnum {
  PAID = 'Paid',
  DEPOSITED = 'Deposited'
}

export const setInvoiceBadgeType = (
  status: InvoiceStatusEnum | InvoiceStatus
): StatusBadgeType => {
  switch (status) {
    case InvoiceStatusEnum.Overdue:
      return 'danger'
    case InvoiceStatusEnum.Paid:
      return 'success'
    case InvoiceStatusEnum.Upcoming:
      return 'warning'
    case InvoiceStatusEnum.Received:
      return 'info'
    case InvoiceStatusEnum.Archived:
      return 'canceled'
    default:
      return 'fill'
  }
}

export const formatInvoiceStatusText = (
  status: InvoiceStatusEnum | InvoiceStatus
) => {
  switch (status) {
    case InvoiceStatusEnum.Received:
      return InvoiceStatusOptionsEnum.PAID
    case InvoiceStatusEnum.Paid:
      return InvoiceStatusOptionsEnum.DEPOSITED
    default:
      return capitalizeFirstLetter(status.toLowerCase())
  }
}

export const setInvoiceBadgeTooltipContent = (status: InvoiceStatusEnum) => {
  switch (status) {
    case InvoiceStatusEnum.Upcoming:
      return 'Unpaid invoice with a due date in the future.'
    case InvoiceStatusEnum.Overdue:
      return 'Unpaid invoice with a due date in the past.'
    case InvoiceStatusEnum.Received:
      return 'Payment initiated by customer. An ACH payment with a paid status may fail and will revert to upcoming or overdue.'
    case InvoiceStatusEnum.Paid:
      return 'Payout successfully deposited.'
  }
}

export const formatStatusText = (status: TransactionStatus) => {
  switch (status) {
    case TransactionStatus.Succeeded:
      return 'Paid'

    case TransactionStatus.Processing:
    case TransactionStatus.Created:
      return 'Pending'

    default:
      return capitalizeFirstLetter(status.toLowerCase())
  }
}

export const setSubscriptionPaymentBadge = (
  status: SubscriptionPaymentStatus
) => {
  switch (status) {
    case SubscriptionPaymentStatus.Paid:
      return 'success'
    case SubscriptionPaymentStatus.Overdue:
      return 'danger'
    case SubscriptionPaymentStatus.Processing:
    default:
      return 'warning'
  }
}

export const setInstallmentBadge = (
  status: InstallmentStatus
): StatusBadgeType => {
  switch (status) {
    case InstallmentStatus.Succeeded:
      return 'success'
    case InstallmentStatus.Overdue:
    case InstallmentStatus.Canceled:
      return 'danger'
    case InstallmentStatus.Processing:
    case InstallmentStatus.Pending:
      return 'outline'
    case InstallmentStatus.Scheduled:
      return 'info'
    default:
      return 'fill'
  }
}

export const formatInstallmentStatusText = (status: InstallmentStatus) => {
  switch (status) {
    case InstallmentStatus.Succeeded:
      return 'Paid'
    case InstallmentStatus.Overdue:
      return 'Overdue'
    case InstallmentStatus.Canceled:
      return 'Failed'
    case InstallmentStatus.Processing:
    case InstallmentStatus.Pending:
      return 'Processing'
    case InstallmentStatus.Scheduled:
      return 'Scheduled'
  }
}

export const formatBnplStatusText = (status: BnplStatus) => {
  switch (status) {
    case BnplStatus.Inprocess:
      return 'In process'
    case BnplStatus.Inreview:
      return 'In review'
    default:
      return capitalizeFirstLetter(status?.toLowerCase())
  }
}

export const setBnplBadge = (status: BnplStatus): StatusBadgeType => {
  switch (status) {
    case BnplStatus.Creating:
    case BnplStatus.Inreview:
      return 'warning'
    case BnplStatus.Inprocess:
      return 'success'
    case BnplStatus.Rejected:
    case BnplStatus.Failed:
      return 'danger'
    default:
      return 'outline'
  }
}
